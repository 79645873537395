import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { IWorkflow, WorkflowContext } from '@/interface/workflow'
// services
import { getWorkflowContextDeep, getWorkflowVariables, updateWorkflow } from '@/service/workflows'
import { setSessionWorkflow } from '@/utils/sessionWorkflow'
import { MUST_LAST_NODE } from '@/utils/constants'
import { checkWorkflowChooseError } from '@/utils/utils'
import { checkNodeErrors } from '@/pages/workflow/form/components/DslFlow/utils/helpers'

interface IState {
  // 数组类型，兼容if和子节点的情况
  taskIndex: number[]
  group: string
  type: string
  workflow: IWorkflow
  workflowContextDeep: WorkflowContext[]
  setTaskIndex: (index: number[]) => void
  setGroup: (group: string) => void
  setType: (type: string) => void
  setWorkflow: (workflow: IWorkflow, isDirty?: boolean, checkChooseError?: boolean) => void
  setContextDeep: (pipelineId?: string) => Promise<void>
}

const useWorkflowStore = create<IState>()(
  devtools((set: any) => ({
    taskIndex: [0],
    group: 'trigger',
    type: '',
    workflow: {
      state: 0, // 默认是停用
      label: '',
      tasks: [] as any[]
    },
    workflowContextDeep: [] as WorkflowContext[],
    // 当前激活的节点下标
    setTaskIndex: (index: number[]) => set({ taskIndex: index }),
    // 当前节点类型分组
    setGroup: (group: string) => set({ group }),
    // 当前节点类型
    setType: (type: string) => set({ type }),
    // 当前工作流
    setWorkflow: (workflow, isDirty = true, checkChooseError = false) => {
      // 对tasks进行排序，确保MUST_LAST_NODE类型的节点在最后
      if (workflow?.tasks?.length > 0) {
        workflow.tasks = workflow?.tasks?.sort((a, b) => {
          if (a.type === MUST_LAST_NODE) return 1
          if (b.type === MUST_LAST_NODE) return -1
          return 0
        })
      }
      // 排序方法放在这里，防止步骤数字错误
      let _upIndex = 0
      workflow?.tasks?.forEach((item: any, i) => {
        item.orderIndex = _upIndex + 1
        _upIndex = item.orderIndex
        item.levelIndex = [i]
        item?.tasks?.forEach((initem: any, j) => {
          initem.orderIndex = _upIndex + 1
          _upIndex = initem?.orderIndex
          initem.levelIndex = [i, j]
          initem?.tasks?.forEach((v: any, k) => {
            v.levelIndex = [i, j, k]
            v.orderIndex = _upIndex + 1
            _upIndex = v?.orderIndex
            item.tasksMaxLevel = 3
          })
        })
      })

      if (checkChooseError) {
        const _tasks = checkWorkflowChooseError(workflow?.tasks)
        workflow.tasks = _tasks

        if (!checkNodeErrors(_tasks)) {
          updateWorkflow(workflow.id + '', workflow)
        }
      }

      set({ workflow: { ...workflow, isDirty } })
      setSessionWorkflow(workflow?.tasks)
    },

    setContextDeep: async (pipelineId?: string) => {
      if (pipelineId) {
        const res = await getWorkflowContextDeep(pipelineId)
        res && set({ workflowContextDeep: res })
      } else {
        const res = await getWorkflowVariables()
        res && set({ workflowContextDeep: res })
      }
    }
  }))
)

export default useWorkflowStore
