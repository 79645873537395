import { nanoid } from 'nanoid'
import { IWorkflow, TriggerNode, Task } from '@/interface/workflow'

// 生成节点名称
export const generateNodeName = (group: string): string => {
  return `${group}_${nanoid(4)}`
}

// 检查逻辑节点是否有子节点
export const checkLogicNodesHaveChildren = (tasks: any[], controlTypes: string[]): boolean => {
  return tasks.some(function checkTask(item) {
    if (controlTypes.includes(item.type) && (!item.tasks || item.tasks.length === 0)) {
      return true
    }
    if (item.tasks && item.tasks.length > 0) {
      return item.tasks.some(checkTask)
    }
    return false
  })
}

// 更新节点索引
export const updateNodeIndexes = (workflow: IWorkflow): void => {
  let _upIndex = 0
  workflow?.tasks?.forEach((item: any, i) => {
    item.orderIndex = _upIndex + 1
    _upIndex = item.orderIndex
    item.levelIndex = [i]
    item?.tasks?.forEach((initem: any, j) => {
      initem.orderIndex = _upIndex + 1
      _upIndex = initem.orderIndex
      initem.levelIndex = [i, j]
      initem?.tasks?.forEach((v: any, k) => {
        v.levelIndex = [i, j, k]
        v.orderIndex = _upIndex + 1
        _upIndex = v.orderIndex
        item.tasksMaxLevel = 3
      })
    })
  })
}

// 创建初始化 HAB 触发器节点
export const createInitialHabTrigger = (): TriggerNode => {
  return {
    desc: 'HAB触发器',
    label: 'HAB触发器',
    groupName: 'trigger',
    type: 'hab-trigger-task',
    status: 'error',
    levelIndex: [0],
    orderIndex: 1,
    name: `trigger_${nanoid(4)}`
  }
}

export const checkNodeErrors = (tasks: Task[]): boolean => {
  for (const task of tasks) {
    if (task?.status === 'error') {
      return true
    }
    if (task.tasks && checkNodeErrors(task.tasks)) {
      return true
    }
  }
  return false
}
