import { Task } from '@/interface/workflow'
import moment from 'moment'
import { customAlphabet } from 'nanoid'
import { cloneDeep } from 'lodash'

/**
 * 根据dsl名称生成节点名称
 * @param groupName 节点分组
 * @param tasks 当前工作流所有节点
 * @param name dsl的名称
 * @returns 名称
 */
export function genderNodeName(groupName: string) {
  const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789', 6)
  const id = nanoid()
  return `${groupName?.replaceAll('/', '')}_${id}`
}

/**
 * @description 函数重新排序
 * @author Yun.kou
 */
export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

// ----------------------------------------------------------------------
// sonar 安全问题：Origins should be verified during cross-origin communications
// 目前需要 postMessage 通信的地址有：
// 测试环境：https://unity-dev.ekuaibao.net/
// release环境：https://unity-test.ekuaibao.net/
// 正式环境：https://unity.ekuaibao.com/
// QA环境：https://unity-pre.ekuaibao.com/

export const postMessage = (data: any) => {
  window?.parent?.postMessage(data, 'https://unity-dev.ekuaibao.net/')
  window?.parent?.postMessage(data, 'https://unity-test.ekuaibao.net/')
  window?.parent?.postMessage(data, 'https://unity.ekuaibao.com/')
  window?.parent?.postMessage(data, 'https://unity-pre.ekuaibao.com/')
}

// ----------------------------------------------------------------------
//  将object 多层级格式改转换成用keyPath为一层级的格式
export const flattenObject = (obj: Object, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : ''
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(acc, flattenObject(obj[k], pre + k))
    } else {
      acc[pre + k] = obj[k]
    }
    return acc
  }, {})

export function findTaskByName(tasks: Task[], targetName: string) {
  for (const task of tasks) {
    if (task?.name === targetName) {
      return task
    }
    if (task?.tasks) {
      const foundTask = findTaskByName(task.tasks, targetName)
      if (foundTask) {
        return foundTask
      }
    }
  }
  return undefined
}

export function getTasksByTaskIndex(tasks, taskIndex) {
  let taskList = tasks
  return taskIndex.reduce((prev, next) => {
    prev.push(taskList[next])
    taskList = taskList[next]?.tasks ?? []
    return prev
  }, [])
}

export function getTaskNameByIndices(data, _indices, name = 'name') {
  const indices = _indices.slice()
  // 递归终止条件：当indices为空时，返回当前data的name属性
  if (indices.length === 0) {
    return data[name]
  }

  // 取出indices的第一个元素，并从indices中移除它
  const index = indices.shift()

  // 检查当前索引是否在data的范围内
  if (index < 0 || index >= data.length) {
    return 'Index out of bounds' // 索引超出范围
  }

  // 获取当前索引对应的元素
  const currentItem = data[index]

  // 如果当前元素有tasks属性，且indices还有剩余元素，则递归调用当前函数
  if ('tasks' in currentItem && indices.length > 0) {
    return getTaskNameByIndices(currentItem.tasks, indices)
  }

  // 如果当前元素没有tasks属性，或者indices没有剩余元素，则返回当前元素的name属性
  return currentItem[name]
}

export function deepCompare(a, b) {
  const aProps = Object.keys(a)

  for (const prop of aProps) {
    const aProp = a[prop]
    const bProp = b[prop]

    if (Array.isArray(aProp) && Array.isArray(bProp)) {
      if (aProp.length !== bProp.length || !aProp.every((val, index) => deepCompare(val, bProp[index]))) {
        return false
      }
    } else if (typeof aProp === 'object' && typeof bProp === 'object') {
      if (!deepCompare(aProp, bProp)) {
        return false
      }
    } else if (aProp !== bProp) {
      return false
    }
  }

  return true
}

export function addSelectableProperty(tree) {
  tree.forEach(node => {
    if (node.children && node.children.length > 0) {
      // 如果节点有子节点，则添加 selectable: false
      node.selectable = false
      // 递归处理子节点
      addSelectableProperty(node.children)
    }
  })
}

/**
 * 处理工作流状态
 * @param runItems
 * @returns
 */
export const makeTasksStatus = (runItems, TempWorkflow, convertStatus: (status) => void) => {
  const _finalTask = JSON.parse(JSON.stringify(TempWorkflow.tasks))
  TempWorkflow.tasks.forEach((item: any, index: number) => {
    const _itemInfo = runItems.find((resItem: any) => resItem.name == item.name)
    if (_itemInfo) {
      _finalTask[index] = { ..._finalTask[index], runStatus: convertStatus(_itemInfo.status) }
    }
    // 代表有子节点的信息
    if (item.tasks && item.tasks.length > 0) {
      item.tasks.forEach((innerTask: any, innerIndex: number) => {
        const _foundItemInfo = runItems.find((resItem: any) => resItem.name === innerTask.name)
        if (_foundItemInfo) {
          _finalTask[index].tasks[innerIndex] = {
            ..._finalTask[index].tasks[innerIndex],
            runStatus: convertStatus(_foundItemInfo.status)
          }
        }

        // 继续有子节点
        if (innerTask.tasks && innerTask.tasks.length > 0) {
          innerTask.tasks.forEach((childItem: any, childIndex: number) => {
            const _foundChildItemInfo = runItems.find((resItem: any) => resItem.name === childItem.name)
            if (_foundChildItemInfo) {
              _finalTask[index].tasks[innerIndex].tasks[childIndex] = {
                ..._finalTask[index].tasks[innerIndex].tasks[childIndex],
                runStatus: convertStatus(_foundChildItemInfo.status)
              }
            }
          })
        }
      })
    }
  })
  return _finalTask
}
// 更新本地化配置以自定义相对时间的显示
moment.updateLocale('zh-cn', {
  relativeTime: {
    future: '未来 %s',
    past: '%s前',
    s: '刚刚',
    m: '1 分钟',
    mm: '%d 分钟',
    h: '1 小时',
    hh: '%d 小时',
    d: '1 天',
    dd: '%d 天',
    M: '1 个月',
    MM: '%d 个月',
    y: '1 年',
    yy: '%d 年'
  }
})

/**
 * 格式化日期为易读的字符串
 * 如果给定的日期是在过去的 3 小时内，它将返回相对于当前时间的字符串
 * 否则，它将返回格式化的日期和时间
 *
 * @param date - 要格式化的日期
 * @returns 格式化后的日期字符串
 */
export function formatDate(date) {
  // 如果日期为空，则返回空字符串
  if (!date) {
    return '--'
  }
  const mDate = moment(date)
  // 计算当前时间与给定时间的差值（以秒为单位）
  const secondsDiff = moment().diff(mDate, 'seconds')

  // 如果时间差小于 60 秒，则显示 "刚刚"
  if (secondsDiff < 180) {
    return '刚刚'
  }

  // 如果时间差在 60 秒到 3 小时之间，则使用本地化后的相对时间
  const hoursDiff = moment().diff(mDate, 'hours')
  if (hoursDiff < 3) {
    return mDate.fromNow()
  }

  // 如果时间差大于 3 小时，则使用固定格式
  return mDate.format('YYYY-MM-DD HH:mm:ss')
}

type Choose = {
  key: string
  properties: any[]
  label: string
}

// 检查工作流是否存在变量和表达式错误引用
export const checkWorkflowChooseError = (tasks: Task[]) => {
  const _tasks = cloneDeep(tasks)
  // 1️⃣ 定义 task 检查方法
  const checkTask = (currentTask: Task) => {
    // 只检查当前节点，不遍历子节点
    const task = cloneDeep(currentTask)
    delete task.tasks

    // 将 task 所有 key 拍平
    const flattenTask = flattenObject(task)
    // 过滤出以 .value 结尾的 key，并检查这些 value 是否是表达式
    const valueKeyPaths = Object.keys(flattenTask).filter(key => {
      const value = flattenTask[key]
      return typeof value === 'string' && value?.includes('@{choose(')
    })
    // 现在 valueKeyPaths 中存储了所有可能存在错误引用的表达式
    // 结构化表达式，并检查是否存在错误引用
    const structuredChooses: Choose[] = []
    valueKeyPaths.forEach(key => {
      const value = flattenTask[key]
      const REX = /@{choose\('(.+?)'\)}/g
      // 使用 match() 方法获取所有匹配
      const matches = value.match(REX)

      // 输出捕获的内容
      if (matches) {
        matches.forEach(match => {
          try {
            // 处理匹配的 choose 字符串 @{choose('...')}
            // 去掉 @{choose(' 和 ')}
            let str = match.replace(/@{choose\(|\)}/g, '').replace(/'/g, '')
            // 目前测试出表达式格式存在 bug, 会有 }} 结尾处多一个 } 应该去掉
            str = str.replace(/}}$/, '}') // 将结尾的 }} 替换为单个 }
            // 将 str 转换为 JSON 对象
            const choose = JSON.parse(str) as Choose
            structuredChooses.push(choose)
          } catch (error) {
            console.error(error)
          }
        })
      } else {
        console.log('No matches found')
      }
    })

    if (structuredChooses.length === 0) {
      return
    }

    // 检查 structuredChoose 是否存在错误引用
    structuredChooses.some(choose => {
      // choose 中 key 为引用节点的 name
      // 1️⃣ 判断 choose 中 key 是否在 _tasks 中存在
      const refTask = findTaskByName(_tasks, choose.key)

      if (!refTask) {
        task.status = 'error'
        return true
      }
      // 2️⃣ 判断 refTask 的 orderIndex 是否小于 task 的 orderIndex（节点只能引用上文节点）
      if (refTask?.orderIndex > task?.orderIndex) {
        task.status = 'error'
        return true
      }

      // 3️⃣ 判断 refTask 和 task 的 levelIndex，是否同父或者同祖

      // 重构判断方法
      // 首先在两个 levelIndex 第一位补一个 0 ，代表根节点
      // 然后对比在 两个 levelIndex 去掉最后一位 代表父节点，相当于作用域
      // 最后逐个对比处理好的 levelIndex 是否相等
      // 只要对齐 refLevelIndex 即可

      // 处理 levelIndex
      const refLevelIndex = refTask.levelIndex
      const taskLevelIndex = task.levelIndex

      // 1. 在两个 levelIndex 第一位补 0,代表根节点
      const processedRefIndex = [0, ...refLevelIndex]
      const processedTaskIndex = [0, ...taskLevelIndex]

      // 2. 去掉最后一位,代表父节点作用域
      const refScope = processedRefIndex.slice(0, -1)
      const taskScope = processedTaskIndex.slice(0, -1)

      // 3. 逐个对比处理好的 levelIndex 是否相等
      const isSame = refScope.every((_, i) => refScope[i] === taskScope[i])
      if (!isSame) {
        task.status = 'error'
        return true
      }
    })

    currentTask.status = task.status
  }

  // 2️⃣ 递归检查 _tasks 中所有节点是否存在错误引用
  const checkTasks = (tasks: Task[]) => {
    tasks.forEach(checkTask)
    tasks.forEach(item => {
      if (item.tasks) {
        checkTasks(item.tasks)
      }
    })
  }

  checkTasks(_tasks)

  return _tasks
}
